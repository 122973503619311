import ApiService from "@/service/ApiService";

export default function useLogin() {
  const CheckUser = async (id,by,type) => {
    ApiService.setHeader();
    return await ApiService.add("register_student",id,by,type);
  };

  return {
    CheckUser,
  };
}
